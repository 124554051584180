import React from "react";
import { Link } from "gatsby";

import Logo from "../img/faircloth-law-logo-white.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <Logo
            alt="Faircloth Law"
            style={{
              width: "14em",
              height: "10em"
            }}
          />
          <div className="has-text-centered">
            <div className="" style={{ height: "100%" }}>
              <a className="button is-primary" href="tel:507-205-2002‬">
                <strong>Call 507-205-2002‬</strong>
              </a>
            </div>
          </div>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <a
                        className="navbar-item"
                        href="https://secure.lawpay.com/pages/marcyfaircloth/operating"
                      >
                        Pay Invoice
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
